/*------------------------------Global Styles------------------------------*/

html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'Futura';
    src: url("/public/fonts/futura/futura\ medium\ bt.ttf");
}

* {
    margin: 0px;
    padding: 0px;
    font-family: "Futura" !important;
}

.main   {
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-snap-points-y: repeat(100vh);
}

.main header,
.main main #servicios,
.main main #sectores,
.main main #contacto,
.main footer {
    scroll-snap-align: start;
}

::selection {
    color: black;
    background: #F7941D;
}

.main #fixed-whatsapp   {
    position: fixed;
    bottom: 5vh;
    right: 5vw;
    z-index: 4;
}

.main #fixed-whatsapp img   {
    height: 6vh;
}



@media only screen and (orientation:portrait) and (min-resolution: 190dpi) {
    .main #fixed-whatsapp {
            bottom: 5vw;
            right: 5vw;
        }

    .main #fixed-whatsapp img {
            height: 6vh;
        }
    .main {
        height: 100vh;

        scroll-snap-type: unset;
        scroll-snap-points-y: unset;
    }

    .main header,
    .main main #servicios,
    .main main #sectores,
    .main main #contacto,
    .main footer {
        scroll-snap-align: unset;
    }
}

/* @media only screen and (orientation:landscape) and (min-resolution: 190dpi) {
    .main {
            height: 100vh;

            scroll-snap-type: unset;
            scroll-snap-points-y: unset;
        }

        .main header,
        .main main #servicios,
        .main main #sectores,
        .main main #contacto,
        .main footer {
            scroll-snap-align: unset;
        }
} */



/*------------------------------Navigation Bar------------------------------*/

.navbar {
    background-color: rgba(0, 0, 0, 0.9) !important;
}

nav .navbar-brand  {
    height: 100%;
    font-family: 'Futura';
    font-size: clamp(0.5rem, 3vh, 1rem);
    color: white;
    transition: 0.2s;
}

nav .navbar-brand span  {
    margin: -0.2rem 0;
}

nav .links   {
    font-family: 'Futura';
    font-size: clamp(1rem, 2vh, 1.5rem);
    color: white !important;
    transition: 0.2s;
}

nav .toggleLanguage {
    position: relative;
    z-index: 4;
    border: 0px;
    padding: 0rem 0.5rem;
    font-size: clamp(1rem, 2vh, 2rem);
    font-weight: bold;
    text-decoration: none;
    color: black;
    background-color: white;
    transition: 0.2s;
}

nav .toggleLanguage:hover {
    background-color: #F7941D;
}

nav .toggleLanguage:active {
    transform: translateY(3px);
}

@media only screen and (orientation:portrait) and (min-resolution: 190dpi) {
    nav .links {
            font-size: clamp(1rem, 3vw, 1.5rem);
        }
}

/* nav .cuestionario   {
    font-family: 'Futura';
    font-size: 1.2rem;
    color: black !important;
    transition: 0.2s;
    background-color: rgba(255, 255, 255, 0.9);
} */

nav .links:hover,
nav .navbar-brand:hover {
    color:#F7941D !important;
    transform: translateY(-3px);
}

/* nav .cuestionario:hover {
    color: black;
    background-color: #F7941D;
} */


/*------------------------------Header------------------------------*/

.title-container  {
    position: absolute;
    height: 100vh;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.363);
}

#inicio .title-container img    {
    height: 4vh;
    min-height: 2.5rem;
}

@media only screen and (orientation:portrait) and (min-resolution: 190dpi) {
    #inicio .title-container img {
        height: 8vw;
        min-height: unset;
    }
}

/* @media only screen and (orientation:landscape) and (min-resolution: 190dpi) {
    .title-container {
            position: static;
            height: 150vh;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.363);
        }

    #inicio .title-container img {
        height: 10vh;
        min-height: unset;
    }

    header video {
        display: none;
    }
} */

header .display-2    {
    font-family: 'Futura';
    color: white;
}

header span {
    font-size: clamp(1rem, 3vh, 2rem);
    color: white;
}

/* header a {
    margin: 0 auto;
    border: 0px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #3d2508;
    background-color: #F7941D;
    transition: 0.2s;
    text-decoration: none;
} */

/* header a:hover {
    color: #3d2508;
    background-color: #ffd6a4;
} */

header a    {
    color: white;
    transition: 0.2s;
    font-family: "Segoe UI";
    font-size: max(2vh, 1.5rem);
    text-decoration: underline;
}

header a:hover {
    color: #F7941D;
}

header a:active {
    transform: translateY(-10px);
}

header .background-video    {
    display: flex;
    justify-content: center;
}

header video    {
    filter: blur(10px);
    -webkit-filter: blur(10px);
}

header #phrasesCarousel  {
    position: absolute;
    bottom: 6vh;
    width: 100%;
    height: 30%;
}

header .carousel-inner  {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

header .display-6   {
    color: white;
    text-align: center;
    font-weight: 400;
}

header .carousel-control-prev,
header .carousel-control-next   {
    width: 5%;
}



/*------------------------------Main Content------------------------------*/

/* main    {
    background-color: gray;
} */



/*------------------------------Services------------------------------*/



.accordion-item {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.accordion-body {
    color: white;
}

.accordion-header button  {
    font-size: clamp(1.5rem, 3vh, 2.5rem) !important;
    padding: 0.3rem !important;
}

@media only screen and (orientation:portrait) and (min-resolution: 190dpi) {
    .accordion-header button {
            font-size: clamp(1.5rem, 4vw, 2.5rem) !important;
            padding: 0.3rem !important;
        }
}

.accordion-item .mini-video    {
    overflow: hidden;
}

.accordion-item video   {
    display: flex;
    position: relative;
    left: -20vh;
    height: 60vh;
    object-fit: cover;
}

.accordion-button:not(.collapsed) {
    color: black !important;
    background-color: rgb(247, 148, 29) !important;
}

.accordion-button   {
    color: white !important;
    background-color: #333333 !important;
}

/* .accordion-button:focus {
    box-shadow: 0 0 0 0 !important;
} */

#servicios h1   {
    font-weight: 400;
}

#servicios p {
    text-align: justify;
    text-justify: inter-word;
    font-size: clamp(1rem, 2.5vh, 2rem);
}

#servicios a {
    border: 0px;
    font-size: clamp(1rem, 2vh, 2rem);
    font-weight: bold;
    text-decoration: none;
    color: black;
    background-color: #F7941D;
    transition: 0.2s;
}

@media only screen and (orientation:portrait) and (min-resolution: 190dpi) {
    #servicios p {
            text-align: justify;
            text-justify: inter-word;
            font-size: clamp(1rem, 3vw, 2rem);
        }

        #servicios a {
            border: 0px;
            font-size: clamp(1rem, 3vw, 2rem);
            font-weight: bold;
            text-decoration: none;
            color: black;
            background-color: #F7941D;
            transition: 0.2s;
        }
}

#servicios a:hover {
    background-color: #FFD6A4;
}

#servicios a:active {
    transform: translateY(3px);
}

.form-select    {
    color: white !important;
    background-color: rgb(51, 51, 51) !important;
    border: 0px !important;
    border-radius: 0 !important;
}

/*------------------------------Services Pages------------------------------*/

.service-full   {
    margin-top: 6rem;
    color: white;
}

.service-full p {
    text-align: justify;
    text-justify: inter-word;
    font-size: clamp(1rem, 3vh, 2rem);
}

.service-full .watermark-cover  {
    position: relative;
    top: -80px;
    background-color: black;
}

.service-full .watermark-cover h1   {
    font-size: clamp(1.5rem, 4vh, 2.5rem);
}

.service-full .card{
    position: relative;
    top: -80px;
    width: 30%;
    background-color: black !important;
    border-radius: 0;
}

.service-full .card .card-title {
    font-size: clamp(1rem, 3vh, 2rem);
}

.service-full .card .card-text  {
    text-align: unset;
    text-justify: unset;
    font-size: clamp(1rem, 2vh, 2rem);
}

.service-full .card a {
    color: white;
    text-decoration: none;
    transition: 0.2s;
    text-align: justify;
    text-justify: distribute;
}

.service-full .card span {
    text-decoration: underline;
}

.service-full .card a:hover {
    color: #F7941D;
}

@media only screen and (orientation:portrait) and (max-resolution: 350dpi) {
    .service-full p {
        font-size: clamp(1rem, 3vw, 2rem);
    }

    .service-full .watermark-cover {
        top: 0px;
    }

    .service-full .watermark-cover h1 {
        font-size: clamp(1.5rem, 4vw, 2.5rem);
    }

    .service-full .card {
        position: relative;
        top: 0px;
        width: 100%;
        background-color: black !important;
        border-radius: 0;
    }

    .service-full .card img {
        height: 15vh;
        object-fit: cover;
    }

    .service-full .card .card-title {
        font-size: clamp(1rem, 3vw, 2rem);
    }

    .service-full .card .card-text {
        font-size: clamp(1rem, 2vw, 2rem);
    }
}

@media only screen and (orientation:portrait) and (max-resolution: 192dpi) {
    .service-full .watermark-cover {
        position: relative;
        top: -80px;
        background-color: black;
    }
}

/*------------------------------Sectors------------------------------*/

#sectores h1,
#contacto h1    {
    color: white;
    font-weight: 400;
}

/*slideshow*/

.tech-slideshow {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    z-index: 1;
}

.tech-slideshow>div {
    width: 630vw;
    position: relative;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
}

.tech-slideshow .card   {
    width: 80vw !important;
    height: 70vh;
    margin: 3vh 5vw;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0px !important;
    transition: margin 300ms;
}

.tech-slideshow .card:hover,
.tech-slideshow .card:active    {
    margin: 0vh 5vw 6vh 5vw;
    color: white;
    background-color: black;
    transition: margin 300ms;
}

.tech-slideshow .card-end {
    width: 40vw !important;
    margin: 0 5vw 0 0;
    color: white;
    background-color: black;
    border-radius: 0px !important;
}

.tech-slideshow .card .img-container {
    display: flex !important;
    border-radius: 0px !important;
    align-items: center;
}

.tech-slideshow .card-title {
    font-size: clamp(2rem, 3vh, 2.5rem);
}

.tech-slideshow .card p {
    text-align: justify;
    text-justify: inter-word;
    font-size: clamp(1rem, 3vh, 2rem);
}

.tech-slideshow .img-container {
    height: 30vh;
    overflow: hidden;
}

.tech-slideshow .mover-1 {
    animation: moveSlideshow 30s linear infinite;
}

.tech-slideshow:hover .mover-1,
.tech-slideshow:active .mover-1 {
    animation-play-state: paused;
}

@keyframes moveSlideshow {
    100% {
        transform: translateX(-66.6666%);
    }
}

/*slideshow-back*/

/* .tech-slideshow-back-container  {
    height: 0px;
}

.tech-slideshow-back {
    height: 250px;
    position: relative;
    top: -375px;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
    filter: blur(5px);
}

.tech-slideshow-back>div {
    width: 3000px;
    position: relative;
    top: 0;
    right: 1800px;
    transform: translate3d(0, 0, 0);
}

.tech-slideshow-back .back-card {
    width: 400px;
    height: 300px;
    margin: 0 50px;
    background-color: black;
}

.tech-slideshow-back .mover-2 {
    animation: moveSlideshowBack 30s linear infinite;
}

@keyframes moveSlideshowBack {
    100% {
        transform: translateX(66.6666%);
    }
} */

@media only screen and (orientation:portrait) and (min-resolution: 190dpi) {
        #sectores .tech-slideshow   {
            display: none;
        }

        #sectores .carousel {
            display: block !important;
            height: 70vh;
        }

        #sectores .card   {
            height: 65vh !important;
            color: white;
            background-color: rgba(0, 0, 0, 0.5);
            border-radius: 0px !important;

        }

        #sectores .card .img-container {
            display: flex !important;
            border-radius: 0px !important;
            align-items: center;
        }

        #sectores .card .img-container {
            height: 20vh;
            overflow: hidden;
        }

        #sectores .card h2 {
            font-size: clamp(1.5rem, 4vh, 2.5rem);
        }

        #sectores .card p   {
            text-align: justify;
            text-justify: inter-word;
            font-size: clamp(1rem, 4vw, 2rem);
        }
}



/*------------------------------Cuestionario------------------------------*/

#cuestionario .question {
    width: 50vw;
}

div[class*="Content__Font"] a  {
    display: none !important;
}

#cuestionario .formFooter   {
    display: none !important;
}



/*------------------------------Footer------------------------------*/

.footer-dark {
    padding: 50px 0;
    color: #f0f9ff;
    background-color: rgba(0, 0, 0, 0.9) !important;
}

.footer-dark h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: clamp(1rem, 2vh, 2rem);
}

.footer-dark ul {
    padding: 0;
    list-style: none;
    line-height: 1.6;
    font-size: clamp(1rem, 1.8vh, 2rem);
    margin-bottom: 0;
}

.footer-dark ul a {
    color: inherit;
    text-decoration: none;
    opacity: 0.6;
}

.footer-dark ul a:hover {
    opacity: 0.8;
}

@media (max-width:767px) {
    .footer-dark .item:not(.social) {
        text-align: center;
        padding-bottom: 20px;
    }
}

.footer-dark .item.text {
    margin-bottom: 36px;
}

@media (max-width:767px) {
    .footer-dark .item.text {
        margin-bottom: 0;
    }
}

.footer-dark .item.text p {
    opacity: 0.6;
    margin-bottom: 0;
}

.footer-dark .item.social {
    text-align: center;
}

@media (max-width:991px) {
    .footer-dark .item.social {
        text-align: center;
        margin-top: 20px;
    }
}

.footer-dark .item.social>a {
    font-size: clamp(1rem, 3vh, 2rem);
    width: 36px;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
    margin: 0 8px;
    color: #fff;
    opacity: 0.75;
}

.footer-dark .item.social>a:hover {
    opacity: 0.9;
}

.footer-dark .copyright {
    text-align: center;
    padding-top: 24px;
    opacity: 0.3;
    font-size: clamp(1rem, 2vh, 1.5rem);
    margin-bottom: 0;
}



/*------------------------------Animated Background------------------------------*/

.area {
    position: absolute;
    top: 0;
    z-index: -1;
    background: #161616;
    width: 100%;
    height: 100vh;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;

}

.circles li:nth-child(1) {
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2) {
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3) {
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4) {
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5) {
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6) {
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7) {
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8) {
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9) {
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10) {
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}

@keyframes animate {

    0% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}